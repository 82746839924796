import { getEnv, isDoctor, parseJwt } from 'shared/services/EnvService';
import googleIcon from 'shared/assets/icons/google-icon.svg';
import facebookIcon from 'shared/assets/icons/facebook-icon.svg';
import Alerts from 'shared/components/Alerts';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestLogin } from 'shared/redux/actions/AuthActions';
import { Button, Modal } from 'react-bootstrap';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SocialLogin = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeDataIsReal, setAgreeDataIsReal] = useState(false);
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(false);
  const [agreeStripe, setAgreeStripe] = useState(false);
  const [loginType, setLoginType] = useState('');

  const modalShowTermsModalClose = () => {
    setShowTermsModal(false);
    setAgreeTerms(false);
    setAgreeDataIsReal(false);
    setAgreePrivacyPolicy(false);
    setAgreeStripe(false);
  };

  const getExtraData = () => {
    const data = {
      agreeTermsAndConditions: agreeTerms ? 1 : 0,
      agreePrivacyPolicy: agreePrivacyPolicy ? 1 : 0,
      agreeDataIsReal: agreeDataIsReal ? 1 : 0,
    };
    if (getEnv('APP_TYPE', true) === 'doctor') {
      // @ts-ignore
      data.agreeStripeTermsAndConditions = agreeStripe ? 1 : 0;
    }
    return data;
  };

  const doSocialLogin = (socialLoginType, loginData) => {
    switch (socialLoginType) {
      case 'facebook':
        dispatch(
          requestLogin({
            type: 'facebook',
            data: {
              accessToken: loginData.accessToken,
              ...getExtraData(),
            },
            unconfirmedModalAlert: true,
          }),
        );
        break;
      case 'google':
        dispatch(
          requestLogin({
            type: 'google',
            data: { identityToken: loginData.token, ...getExtraData() },
            unconfirmedModalAlert: true,
          }),
        );
        break;
      case 'apple':
        break;
      default:
        break;
    }
  };
  const onGoogleClick = (e) => {
    e.preventDefault();
    setLoginType('google');
    setShowTermsModal(true);
  };

  const onFacebookLogin = (e) => {
    e.preventDefault();
    setLoginType('facebook');
    setShowTermsModal(true);
  };

  const onTermsAndConditionsConfirmed = () => {
    setShowTermsModal(false);
    if (!agreeTerms || !agreePrivacyPolicy || !agreeDataIsReal || (isDoctor() && !agreeStripe)) {
      Alerts.simpleAlert(t('error'), t('byNotAccepted'));
      return;
    }
    switch (loginType) {
      case 'google':
        // @ts-ignore
        google.accounts.id.initialize({
          client_id: getEnv('WEB_CLIENT_ID') || '',
          callback: (response) => {
            doSocialLogin('google', {
              token: response.credential,
              ...parseJwt(response.credential),
            });
          },
        });
        // @ts-ignore
        google.accounts.id.prompt(); // also display the One Tap dialog
        break;
      case 'facebook':
        // @ts-ignore
        document.getElementsByClassName('facebookLoginBTN')[0]?.click();
        break;
      case 'apple':
        break;
      default:
        break;
    }
  };
  const goToPrivacy = () => {
    navigate(`/support/privacy-policy`);
  };
  const goToTermsAndConditions = () => {
    navigate(`/support/terms-and-conditions`);
  };
  const getStripeTerms = () => {
    navigate(`/doctor/support/stripe-terms-and-conditions`);
  };

  useEffect(() => {
    if (!document.querySelector('#gsi-client')) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.id = 'gsi-client';
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  // @ts-ignore
  return (
    <>
      <div className="social-login">
        {t('active_facebook_flow') === '1' && (
          <LoginSocialFacebook
            appId={getEnv('FACEBOOK_APP_ID') || ''}
            onResolve={({ data }) => {
              doSocialLogin('facebook', data);
            }}
            fieldsProfile="id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            onReject={() => {}}
            className="hidden facebookLoginBTN"
          >
            <></>
          </LoginSocialFacebook>
        )}
        <a className="login-button cursor-pointer" href="/" onClick={onGoogleClick}>
          <img src={googleIcon} alt="Google Login" />
        </a>
        {t('active_facebook_flow') === '1' && (
          <a className="login-button cursor-pointer" href="/" onClick={onFacebookLogin}>
            <img src={facebookIcon} alt="Facebook Login" />
          </a>
        )}
      </div>
      <Modal
        show={showTermsModal}
        onHide={modalShowTermsModalClose}
        animation
        centered
        backdrop="static"
        className="modal-gdpr"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('personalDataProtection')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className={`custom-control custom-checkbox ${
              getEnv('APP_TYPE', true) === 'doctor' ? 'green-checkbox' : 'orange-checkbox'
            }`}
          >
            <input
              className="custom-control-input"
              onChange={() => setAgreeTerms((prevState) => !prevState)}
              id="agree_terms_modal"
              type="checkbox"
              checked={agreeTerms}
            />
            <label htmlFor="agree_terms_modal" className="custom-control-label">
              {t('iAgreeWith')}
              <strong onClick={goToTermsAndConditions}>&nbsp;{t('termsAndConditions')}</strong>
            </label>
          </div>
          {getEnv('APP_TYPE', true) === 'doctor' && (
            <div className="custom-control custom-checkbox green-checkbox">
              <input
                className="custom-control-input"
                onChange={() => setAgreeStripe((prevState) => !prevState)}
                id="agree_stripe_modal"
                type="checkbox"
                checked={agreeStripe}
              />
              <label htmlFor="agree_stripe_modal" className="custom-control-label">
                {t('iAgreeWith')}
                <strong onClick={getStripeTerms}>&nbsp;{t('stripeTermsAndConditions')}</strong>
              </label>
            </div>
          )}
          <div
            className={`custom-control custom-checkbox ${
              getEnv('APP_TYPE', true) === 'doctor' ? 'green-checkbox' : 'orange-checkbox'
            }`}
          >
            <input
              className="custom-control-input"
              onChange={() => setAgreePrivacyPolicy((prevState) => !prevState)}
              id="agree_policy_modal"
              type="checkbox"
              checked={agreePrivacyPolicy}
            />
            <label htmlFor="agree_policy_modal" className="custom-control-label">
              {t('iAgreeWith')}
              <strong onClick={goToPrivacy}>&nbsp;{t('privacyTitle')}</strong>
            </label>
          </div>
          <div
            className={`custom-control custom-checkbox ${
              getEnv('APP_TYPE', true) === 'doctor' ? 'green-checkbox' : 'orange-checkbox'
            }`}
          >
            <input
              className="custom-control-input"
              onChange={() => setAgreeDataIsReal((prevState) => !prevState)}
              id="agree_data_is_real"
              type="checkbox"
              checked={agreeDataIsReal}
            />
            <label htmlFor="agree_data_is_real" className="custom-control-label">
              {t('olderAndRealData')}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={getEnv('APP_TYPE', true) === 'doctor' ? 'green-button' : 'orange-button'}
            onClick={onTermsAndConditionsConfirmed}
          >
            <i className="check-circle-white-icon" />
            {t('confirm')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SocialLogin;
