import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import i18next from 'i18next';
import NavigationService from 'shared/services/NavigationService';
import {
  patientAppointmentDetailsSelector,
  patientAppointmentsSelector,
} from 'shared/redux/selector/PatientAppointmentSelector';
import {
  scheduleAppointmentAPI,
  callNowAppointmentAPI,
  emergencyAppointmentAPI,
  appointmentSelectProfileAPI,
  appointmentDeleteAPI,
  appointmentBookAPI,
  appointmentCancelAPI,
  getPatientPastAppointmentsAPI,
  getPatientUpcomingAppointmentsAPI,
  getPatientAppointmentDetailsAPI,
  appointmentAddReviewAPI,
  appointmentShareMedicalRecordAPI,
  callSpecialistAppointmentAPI,
} from 'shared/redux/api/PatientAppointmentApi';
import {
  APPOINTMENT_ADD_REVIEW_REQUEST,
  APPOINTMENT_BOOK_REQUEST,
  APPOINTMENT_CALL_NOW_REQUEST,
  APPOINTMENT_CALL_SPECIALIST_REQUEST,
  APPOINTMENT_CANCEL_REQUEST,
  APPOINTMENT_DELETE_REQUEST,
  APPOINTMENT_EMERGENCY_REQUEST,
  APPOINTMENT_SCHEDULE_REQUEST,
  APPOINTMENT_SELECT_PROFILE_REQUEST,
  APPOINTMENT_SHARE_MEDICAL_RECORD_REQUEST,
  FETCH_PATIENT_PAST_APPOINTMENTS_REQUEST,
  FETCH_PATIENT_UPCOMING_APPOINTMENTS_REQUEST,
  GET_PATIENT_APPOINTMENT_DETAILS_REQUEST,
} from 'shared/redux/types/PatientAppointmentTypes';
import {
  appointmentAddReviewError,
  appointmentAddReviewSuccess,
  appointmentBookError,
  appointmentBookSuccess,
  appointmentCancelError,
  appointmentCancelRequest,
  appointmentCancelSuccess,
  appointmentDeleteError,
  appointmentDeleteSuccess,
  appointmentLocalStepsRequest,
  appointmentScheduleError,
  appointmentScheduleSuccess,
  appointmentSelectProfileError,
  appointmentSelectProfileRequest,
  appointmentSelectProfileSuccess,
  appointmentShareMedicalRecordError,
  appointmentShareMedicalRecordRequest,
  appointmentShareMedicalRecordSuccess,
  callNowAppointmentError,
  callNowAppointmentSuccess,
  callSpecialistAppointmentError,
  callSpecialistAppointmentSuccess,
  emergencyAppointmentError,
  emergencyAppointmentSuccess,
  fetchPatientPastAppointmentsError,
  fetchPatientPastAppointmentsSuccess,
  fetchPatientUpcomingAppointmentsError,
  fetchPatientUpcomingAppointmentsSuccess,
  getPatientAppointmentDetailsError,
  getPatientAppointmentDetailsRequest,
  getPatientAppointmentDetailsSuccess,
} from 'shared/redux/actions/PatientAppointmentActions';
import Alerts from 'shared/components/Alerts';
import { localAppointmentSelector } from 'shared/redux/selector/LocalAppointmentSelector';
import { locallyCurrentPatientProfile } from 'shared/redux/actions/PatientProfileActions';
import { locallyChooseVoucher } from 'shared/redux/actions/VouchersActions';
import dayjs from 'dayjs';
import { videoCallGetTokenRequest } from '../actions/VideoCallActions';

function* scheduleAppointment(actionType) {
  const appointmentScheduleChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentScheduleChannel);
    try {
      const response = yield call(scheduleAppointmentAPI, payload.forRequest);
      yield put(
        appointmentScheduleSuccess({
          id: response.data?.id,
          duration: response.data?.duration_in_minutes,
          price: response.data?.price_as_string,
          noOfSteps: payload.forUpdate.hasControl ? 6 : 5,
          type: 'programmed',
          status: 'requested',
          appointmentDoctorService: response.data?.service,
          ...payload.forUpdate,
        }),
      );
      yield put(locallyCurrentPatientProfile({}));
      NavigationService.navigate('/patient/appointment/profile');
    } catch ({ message }) {
      yield put(appointmentScheduleError({ message }));
    }
  }
}

function* callNowAppointment(actionType) {
  const callNowAppointmentChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(callNowAppointmentChannel);
    try {
      const response = yield call(callNowAppointmentAPI, payload.forRequest);
      yield put(
        callNowAppointmentSuccess({
          id: response.data?.id,
          duration: response.data?.duration_in_minutes,
          price: response.data?.price_as_string,
          noOfSteps: 4,
          status: 'requested',
          type: 'callNow',
          ...payload.forUpdate,
        }),
      );
      yield put(locallyCurrentPatientProfile({}));
      NavigationService.navigate('/patient/appointment/profile');
    } catch ({ message }) {
      yield put(callNowAppointmentError({ message }));
    }
  }
}

function* emergencyAppointment(actionType) {
  const emergencyAppointmentChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(emergencyAppointmentChannel);
    try {
      const response = yield call(emergencyAppointmentAPI, payload);
      yield put(
        emergencyAppointmentSuccess({
          id: response.data?.id,
          duration: response.data?.duration_in_minutes,
          price: response.data?.price_as_string,
          status: 'requested',
          type: 'emergency',
        }),
      );
      yield put(locallyCurrentPatientProfile({}));
    } catch ({ message }) {
      yield put(emergencyAppointmentError({ message }));
    }
  }
}

function* callSpecialistAppointment(actionType) {
  const callSpecialistAppointmentChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(callSpecialistAppointmentChannel);
    try {
      const response = yield call(
        callSpecialistAppointmentAPI,
        payload.callSpecialistAppointmentPayload.forRequest,
      );
      yield put(
        callSpecialistAppointmentSuccess({
          id: response.data?.id,
          duration: response.data?.duration_in_minutes,
          price: response.data?.price_as_string,
          noOfSteps: 4,
          status: 'requested',
          type: 'callSpecialist',
          startTime: response.data?.time_start,
        }),
      );
      if (payload.profilePayload) {
        const appointmentId = response.data?.id;
        yield put(
          appointmentSelectProfileRequest({
            ...payload.profilePayload,
            forRequest: {
              ...payload.profilePayload.forRequest,
              appointmentId,
            },
            appointmentStatus: response.data?.status,
            appointmentType: 'callSpecialist',
          }),
        );
      }
      // yield put(locallyCurrentPatientProfile({}));
    } catch ({ message }) {
      yield put(callSpecialistAppointmentError({ message }));
    }
  }
}

function* appointmentSelectProfile(actionType) {
  const appointmentSelectProfileChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentSelectProfileChannel);
    try {
      let response = {
        data: {
          time_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        },
      };
      if (payload.appointmentStatus === 'created') {
        response = yield call(appointmentSelectProfileAPI, payload.forRequest);
      }
      if (payload.shareFiles) {
        yield put(
          appointmentShareMedicalRecordRequest({
            appointmentId: payload.forRequest.appointmentId,
            body: {
              appointmentPatientMedicalRecords: payload.requestFiles,
            },
          }),
        );
      }
      yield put(appointmentSelectProfileSuccess(payload.forUpdate));
      if (payload.appointmentType === 'emergency') {
        yield put(appointmentLocalStepsRequest({ stickyStatus: 'SearchDoctor' }));
      } else if (payload.appointmentType === 'callSpecialist') {
        yield put(
          appointmentLocalStepsRequest({
            startTime: response.data?.time_start,
            stickyStatus: 'SearchCallSpecialistDoctor',
          }),
        );
        NavigationService.navigate('/patient/call-specialist/searching-medic');
      }
    } catch (err) {
      // @ts-ignore
      const { message } = err;
      yield put(appointmentSelectProfileError({ message, link: '/patient/medicList' }));
      NavigationService.navigate('/patient/medicList');
    }
  }
}

function* appointmentDelete(actionType) {
  const appointmentDeleteChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentDeleteChannel);
    try {
      yield call(appointmentDeleteAPI, payload);
      yield put(appointmentDeleteSuccess(payload));
    } catch ({ message }) {
      yield put(appointmentDeleteError({ message }));
    }
  }
}

function* appointmentBook(actionType) {
  const appointmentBookChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentBookChannel);
    try {
      yield call(appointmentBookAPI, payload);
      yield put(appointmentBookSuccess(payload));
    } catch ({ message }) {
      yield put(appointmentBookError({ message }));
    }
  }
}

function* appointmentCancel(actionType) {
  const appointmentCancelChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentCancelChannel);
    try {
      if (payload.appointmentId) {
        yield call(appointmentCancelAPI, payload);
        yield put(appointmentCancelSuccess({ id: payload.appointmentId }));
      } else {
        yield put(appointmentCancelSuccess({}));
      }
      yield put(
        appointmentLocalStepsRequest({
          newAppointment: true,
        }),
      );
      yield put(locallyCurrentPatientProfile({}));
      yield put(locallyChooseVoucher({}));
    } catch ({ message }) {
      yield put(appointmentCancelError({ message }));
    }
  }
}

function* getPatientPastAppointments(actionType) {
  const patientPastAppointmentsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patientPastAppointmentsChannel);
    try {
      const safeAction = {
        limit: 10,
        page: 1,
        ...payload,
      };
      const response = yield call(getPatientPastAppointmentsAPI, safeAction);
      yield put(
        fetchPatientPastAppointmentsSuccess({
          ...patientAppointmentsSelector(response.data, true),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchPatientPastAppointmentsError({ message }));
    }
  }
}

function* getPatientUpcomingAppointments(actionType) {
  const patientUpcomingAppointmentsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patientUpcomingAppointmentsChannel);
    try {
      const safeAction = {
        limit: 10,
        page: 1,
        ...payload,
      };
      const response = yield call(getPatientUpcomingAppointmentsAPI, safeAction);
      yield put(
        fetchPatientUpcomingAppointmentsSuccess({
          ...patientAppointmentsSelector(response.data, true),
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(fetchPatientUpcomingAppointmentsError({ message }));
    }
  }
}

function* getPatientAppointmentDetails(actionType) {
  const patientAppointmentDetailsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patientAppointmentDetailsChannel);
    try {
      const response = yield call(getPatientAppointmentDetailsAPI, payload);
      if (payload.updateLocal) {
        const appointmentData = localAppointmentSelector(response.data);
        yield put(appointmentLocalStepsRequest(appointmentData));
      }
      if (payload.alsoTwilio) {
        yield put(videoCallGetTokenRequest({ appointmentId: response?.data?.id }));
      }
      yield put(
        getPatientAppointmentDetailsSuccess(patientAppointmentDetailsSelector(response.data)),
      );
    } catch ({ message }) {
      yield put(getPatientAppointmentDetailsError({ message }));
    }
  }
}

function* appointmentAddReview(actionType) {
  const addReviewChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addReviewChannel);
    try {
      const response = yield call(appointmentAddReviewAPI, payload);
      yield put(appointmentAddReviewSuccess(response.data));
      // if (payload.withAlert) {
      //   Alerts.simpleAlert(`${i18next.t('thanks')}`, `${i18next.t('reviewSuccessfully')}`);
      // }
      // NavigationService.navigate('/patient/consultations', { state: 'history' });
    } catch ({ message }) {
      yield put(appointmentAddReviewError({ message }));
    }
  }
}

function* appointmentAddMedicalRecord(actionType) {
  const addMedicalRecordChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(addMedicalRecordChannel);
    try {
      const response = yield call(appointmentShareMedicalRecordAPI, payload);
      yield put(appointmentShareMedicalRecordSuccess(response.data));
      yield put(getPatientAppointmentDetailsRequest({ id: payload.appointmentId }));
    } catch ({ message }) {
      yield put(appointmentShareMedicalRecordError({ message }));
    }
  }
}

function* patientAppointmentSaga() {
  yield fork(scheduleAppointment, APPOINTMENT_SCHEDULE_REQUEST);
  yield fork(callNowAppointment, APPOINTMENT_CALL_NOW_REQUEST);
  yield fork(emergencyAppointment, APPOINTMENT_EMERGENCY_REQUEST);
  yield fork(callSpecialistAppointment, APPOINTMENT_CALL_SPECIALIST_REQUEST);
  yield fork(appointmentSelectProfile, APPOINTMENT_SELECT_PROFILE_REQUEST);
  yield fork(appointmentDelete, APPOINTMENT_DELETE_REQUEST);
  yield fork(appointmentBook, APPOINTMENT_BOOK_REQUEST);
  yield fork(appointmentCancel, APPOINTMENT_CANCEL_REQUEST);
  yield fork(getPatientPastAppointments, FETCH_PATIENT_PAST_APPOINTMENTS_REQUEST);
  yield fork(getPatientUpcomingAppointments, FETCH_PATIENT_UPCOMING_APPOINTMENTS_REQUEST);
  yield fork(getPatientAppointmentDetails, GET_PATIENT_APPOINTMENT_DETAILS_REQUEST);
  yield fork(appointmentAddReview, APPOINTMENT_ADD_REVIEW_REQUEST);
  yield fork(appointmentAddMedicalRecord, APPOINTMENT_SHARE_MEDICAL_RECORD_REQUEST);
}

export default patientAppointmentSaga;
