import { storeDispatcher, storeStateGetter } from 'shared/redux/ReduxStore';
import { updateDeviceRegIdRequest } from 'shared/redux/actions/UserActions';
import { parseToJson } from 'shared/modules/JsonUtils';
import { stringHashCode } from 'shared/modules/StringUtils';
import { fetchPushesRequest, updatePushesRequest } from 'shared/redux/actions/NotificationActions';
import i18n from 'i18next';
import {
  doctorAcceptsCallRequest,
  doctorAppointmentUpdatesRequest,
  doctorRejectsCallRequest,
  fetchDoctorTodayAppointmentsRequest,
  fetchDoctorUpcomingAppointmentsRequest,
  getAppointmentDetailsRequest,
  getCallSpecialistInfoRequest,
  removeDoctorCallsStackRequest,
  updateDoctorCallsStackRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { updateSharedStateAction } from 'shared/redux/actions/UngroupedActions';
import Alerts from 'shared/components/Alerts';
import {
  videoCallGetTokenRequest,
  videoCallGetTokenSuccess,
} from 'shared/redux/actions/VideoCallActions';
import LocalEventsService from 'shared/services/LocalEventsService';
import NavigationService from 'shared/services/NavigationService';
import { fetchUserProfileRequest } from 'shared/redux/actions/UserProfileActions';
import StorageService from 'shared/services/StorageService';
import firebase from 'firebase';
import dayjs from 'dayjs';

const onTokenChanged = (newToken) => {
  storeDispatcher(updateDeviceRegIdRequest(newToken));
};

const onForegroundPushReceived = (pushPayload) => {
  const { isLoggedIn } = storeStateGetter('authState');

  if (!isLoggedIn) {
    return;
  }

  const { callStatus: appointmentCallStatus } = storeStateGetter('doctorAppointmentState');
  const { data: customData, messageId } = pushPayload;
  const notificationId = stringHashCode(messageId);
  const {
    type: notificationType,
    metadata: rawMetadata = {},
    body,
    title,
    uuid: notificationUuid,
  } = customData;
  const metadata = parseToJson(rawMetadata);
  const { doctor = {}, patient = {}, appointment = {} } = metadata;
  const {
    id: doctorId,
    firstName: doctorFirstName,
    lastName: doctorLastName,
    fullNameWithTitle: doctorName,
    imgUrl: doctorImage,
    averageRating: doctorAverageRatingRating,
    countRating: doctorCountRating,
    isResident: doctorIsResident,
  } = doctor;
  const {
    id: patientId,
    firstName: patientFirstName,
    lastName: patientLastName,
    fullNameWithTitle: patientName = `${patientFirstName} ${patientLastName}`,
    pictureMedia: patientImage,
    birthDate: patientBirthDate,
    age: patientAge,
  } = patient;
  const {
    id: appointmentId = 0,
    timeStart: startTime = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    timeEnd: endTime = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    timeEndWithPause = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    price,
    priceAsString,
    type,
    typeName: appointmentType = '',
    specialization = '',
    timeOptionsAsArray = [],
  } = appointment;

  if (
    StorageService.pushNotificationRef(notificationId).split('_').indexOf(`${notificationId}`) > -1
  ) {
    return;
  }

  const { isNotificationInProgress } = storeStateGetter('sharedState');
  const { isResident } = storeStateGetter('userProfileState');
  const priorityPushes = [
    'force_end_consultation_for_doctor',
    'consultation_ended_by_patient',
    'incoming_call_now_appointment',
    'consultation_start_now_for_doctor',
    'patient_searching_for_emergency',
    'patient_searching_for_call_specialist',
    'you_won_call_specialist',
    'doctor_missed_call_now',
    'expired_consultation_for_doctor',
    'expired_emergency_search_doctor',
    'expired_free_talk_search_doctor',
    'another_doctor_accepted_emergency',
    'another_doctor_accept_free_talk',
    'another_doctor_won_call_specialist',
    'user_free_talk_cancelled_search',
    'user_emergency_cancelled_search',
    'patient_cancel_consultation',
    'call_specialist_patient_left_without_a_doctor',
    'patient_can_not_pay_now',
  ];
  const notIgnorablePushes = [
    'patient_appointment_medical_record_added',
    'force_end_consultation_for_doctor',
    'consultation_ended_by_patient',
    'incoming_call_now_appointment',
    'consultation_start_now_for_doctor',
    'patient_searching_for_emergency',
    'patient_searching_for_free_talk',
    'patient_searching_for_call_specialist',
    'you_won_call_specialist',
    'doctor_missed_call_now',
    'expired_consultation_for_doctor',
    'expired_emergency_search_doctor',
    'expired_free_talk_search_doctor',
    'another_doctor_accepted_emergency',
    'another_doctor_accept_free_talk',
    'another_doctor_won_call_specialist',
    'user_free_talk_cancelled_search',
    'user_emergency_cancelled_search',
    'patient_cancel_consultation',
    'call_specialist_patient_left_without_a_doctor',
    'patient_can_not_pay_now',
  ];

  if (isNotificationInProgress && priorityPushes.indexOf(notificationType) === -1) {
    return;
  }

  if (
    ['active', 'minimized'].indexOf(appointmentCallStatus) > -1 &&
    notIgnorablePushes.indexOf(notificationType) === -1
  ) {
    return;
  }

  storeDispatcher(updateSharedStateAction({ isNotificationInProgress: true }));
  storeDispatcher(fetchPushesRequest({}));

  switch (notificationType) {
    case 'incoming_call_now_appointment':
    case 'patient_searching_for_emergency':
    case 'patient_searching_for_free_talk':
      storeDispatcher(
        doctorAppointmentUpdatesRequest({
          patient,
          id: appointmentId,
          appointmentId,
          notificationType,
          type: appointmentType,
        }),
      );
      storeDispatcher(getAppointmentDetailsRequest({ id: appointmentId }));
      storeDispatcher(fetchDoctorUpcomingAppointmentsRequest({}));
      storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));
      Alerts.doctorIncomingCallAlert(
        title,
        patientImage,
        patientFirstName,
        patientLastName,
        patientName,
        patientAge > 0 ? `${patientAge} ${i18n.t('years')}` : '',
        () => {
          storeDispatcher(
            updateSharedStateAction({
              showWaitingTime: true,
              showNextAppointment: false,
            }),
          );
        },
        () => {
          storeDispatcher(doctorRejectsCallRequest({ id: appointmentId }));
          storeDispatcher(
            updateSharedStateAction({
              showWaitingTime: false,
              showNextAppointment: false,
              isNotificationInProgress: false,
            }),
          );
        },
      );
      break;
    case 'patient_searching_for_call_specialist':
      storeDispatcher(updateSharedStateAction({ timeOptionsAsArray }));
      storeDispatcher(
        updateDoctorCallsStackRequest([
          {
            id: appointmentId,
            state: 'waitingForAction',
            ...metadata,
          },
        ]),
      );
      Alerts.doctorIncomingCallSpecialistAlert(
        title,
        priceAsString,
        startTime,
        timeOptionsAsArray,
        (timeEstimation) => {
          if (!timeEstimation) {
            return;
          }
          storeDispatcher(
            doctorAcceptsCallRequest({
              // @ts-ignore
              data: { appointmentId, bidTime: timeEstimation },
            }),
          );
          storeDispatcher(
            updateDoctorCallsStackRequest([
              {
                id: appointmentId,
                ...metadata,
                state: 'pendingResult',
              },
            ]),
          );
        },
        () => {
          storeDispatcher(doctorRejectsCallRequest({ id: appointmentId }));
          storeDispatcher(removeDoctorCallsStackRequest({ id: appointmentId }));
          storeDispatcher(
            updateSharedStateAction({
              showWaitingTime: false,
              showNextAppointment: false,
              isNotificationInProgress: false,
            }),
          );
          storeDispatcher(getCallSpecialistInfoRequest({}));
        },
      );
      break;
    case 'you_won_call_specialist':
      Alerts.doctorWonCallSpecialistAlert(
        startTime,
        patientName,
        patientFirstName,
        patientLastName,
        patientAge > 0 ? `${patientAge} ${i18n.t('years')}` : '',
      );
      storeDispatcher(removeDoctorCallsStackRequest({ id: appointmentId }));
      storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));
      break;
    case 'payment_confirmed_for_appointment':
      Alerts.simpleAlert(`${i18n.t('info')}`, body);
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      storeDispatcher(fetchDoctorUpcomingAppointmentsRequest({}));
      storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));
      break;
    case 'consultation_start_now_for_doctor':
      localStorage.setItem('Answer_Call', `false`);
      Alerts.actionAlert(title, body, `${i18n.t('answer')}`, () => {
        storeDispatcher(getAppointmentDetailsRequest({ id: appointmentId }));
        storeDispatcher(
          doctorAppointmentUpdatesRequest({
            startTime,
            endTime,
            timeEndWithPause,
            enableVideo: !isResident,
            isInitialTime: true,
            tmpConclusion: null,
          }),
        );
        storeDispatcher(videoCallGetTokenRequest({ appointmentId }));
        storeDispatcher(
          updateSharedStateAction({ showNextAppointment: false, isNotificationInProgress: false }),
        );
        localStorage.setItem('Answer_Call', `true`);
      });
      break;
    case 'expired_consultation_for_doctor':
      Alerts.actionAlert(`${i18n.t('info')}`, body, 'Ok', () => {
        storeDispatcher(
          doctorAppointmentUpdatesRequest({
            status: 'ended',
            callStatus: 'terminated',
            isInitialTime: true,
          }),
        );
        storeDispatcher(videoCallGetTokenSuccess({}));
        storeDispatcher(
          updateSharedStateAction({ isNotificationInProgress: false, showNextAppointment: false }),
        );
      });
      break;
    case 'force_end_consultation_for_doctor':
    case 'consultation_ended_by_patient':
      storeDispatcher(
        doctorAppointmentUpdatesRequest({ status: 'needConclusion', callStatus: 'terminated' }),
      );
      LocalEventsService.emit('callEnded', { appointmentId, appointmentType });
      storeDispatcher(videoCallGetTokenSuccess({}));
      storeDispatcher(
        updateSharedStateAction({ isNotificationInProgress: false, showNextAppointment: false }),
      );
      Alerts.simpleAlert(title, body);
      break;
    case 'doctor_appointment_no_conclusion_added':
      Alerts.twoActionsAlert(
        `${i18n.t('info')}`,
        body,
        `${i18n.t('cancel')}`,
        () => {
          storeDispatcher(
            updateSharedStateAction({
              isNotificationInProgress: false,
            }),
          );
        },
        'Ok',
        () => {
          storeDispatcher(
            updateSharedStateAction({
              isNotificationInProgress: false,
            }),
          );
          storeDispatcher(getAppointmentDetailsRequest({ id: appointmentId }));
          if (appointmentId !== 0) {
            NavigationService.navigate(
              `/doctor/final-consultation/${appointmentId}/review-conclusions`,
            );
          }
        },
      );
      break;
    case 'patient_sent_review':
      Alerts.twoActionsAlert(
        title,
        body,
        `${i18n.t('ignore')}`,
        () => {
          storeDispatcher(
            updateSharedStateAction({
              isNotificationInProgress: false,
            }),
          );
        },
        `${i18n.t('seeNow')}`,
        () => {
          storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
          NavigationService.navigate('/doctor/account');
        },
      );
      break;
    case 'patient_appointment_medical_record_added':
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      storeDispatcher(getAppointmentDetailsRequest({ id: appointmentId }));
      Alerts.simpleAlert(title, body);
      break;
    case 'patient_can_not_pay_now':
    case 'doctor_missed_call_now':
    case 'activity_check_availability_disabled':
    case 'reminder_doctor_timetable_end':
    case 'patient_cancel_consultation':
    case 'patient_cancel_appointment':
    case 'patient_can_not_pay_programmed':
    case 'user_free_talk_cancelled_search':
    case 'user_emergency_cancelled_search':
      storeDispatcher(removeDoctorCallsStackRequest({ id: appointmentId }));
      storeDispatcher(
        doctorAppointmentUpdatesRequest({ status: 'expired', callStatus: 'expired' }),
      );
      storeDispatcher(
        updateSharedStateAction({
          showNextAppointment: false,
          showWaitingTime: false,
          isNotificationInProgress: false,
        }),
      );
      storeDispatcher(fetchUserProfileRequest({}));
      storeDispatcher(fetchDoctorUpcomingAppointmentsRequest({}));
      storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));
      if (
        notificationType === 'activity_check_availability_disabled' ||
        notificationType === 'call_specialist_patient_left_without_a_doctor' ||
        notificationType === 'patient_cancel_consultation'
      ) {
        NavigationService.navigate('/doctor/home');
      }
      Alerts.simpleAlert(title, body);
      break;
    case 'expired_emergency_search_doctor':
    case 'expired_free_talk_search_doctor':
    case 'another_doctor_accepted_emergency':
    case 'another_doctor_accept_free_talk':
      storeDispatcher(
        updateSharedStateAction({
          showNextAppointment: false,
          showWaitingTime: false,
          isNotificationInProgress: false,
        }),
      );
      break;
    case 'another_doctor_won_call_specialist':
      Alerts.doctorLostCallSpecialistAlert();
      storeDispatcher(
        updateSharedStateAction({
          showNextAppointment: false,
          showWaitingTime: false,
          isNotificationInProgress: false,
        }),
      );
      storeDispatcher(removeDoctorCallsStackRequest({ id: appointmentId }));
      storeDispatcher(getCallSpecialistInfoRequest({}));
      break;
    case 'call_specialist_patient_left_without_a_doctor':
      Alerts.simpleAlert(title, body);
      storeDispatcher(removeDoctorCallsStackRequest({ id: appointmentId }));
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      storeDispatcher(getCallSpecialistInfoRequest({}));
      break;
    case 'activity_check_reminder':
      Alerts.actionAlert(title, body, 'Ok', () => {
        storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      });
      break;
    case 'new_programmed_appointment_for_doctor':
      Alerts.simpleAlert(title, body);
      storeDispatcher(fetchDoctorUpcomingAppointmentsRequest({}));
      storeDispatcher(fetchDoctorTodayAppointmentsRequest({}));
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      break;
    default:
      Alerts.simpleAlert(title, body);
      storeDispatcher(updateSharedStateAction({ isNotificationInProgress: false }));
      break;
  }

  if (!notificationUuid) return;

  storeDispatcher(
    updatePushesRequest({
      forRequest: {
        notificationId: notificationUuid,
        status: 'answered',
      },
      forUpdate: { id: undefined, props: { isAnswered: undefined } },
    }),
  );
};

const updateDeviceRegId = async () => {
  const deviceRegId = await firebase.getWebToken();
  if (deviceRegId.deviceRegId) {
    console.log(
      `%cDeviceRegistrationId:\n${deviceRegId.deviceRegId}`,
      'color:#FF6A39; font-family:monospace; font-size: 15px;',
    );
    onTokenChanged(deviceRegId.deviceRegId);
  } else {
    NavigationService.navigate('/support/permissions?d=d', { state: 'doctor' });
  }
};

export default {
  onTokenChanged,
  onForegroundPushReceived,
  updateDeviceRegId,
};
