import 'patient/styles/account-personal-data.css';
import React, { useState, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { patchPatientProfileRequest } from 'shared/redux/actions/PatientProfileActions';
import { getEnv } from 'shared/services/EnvService';
import Alerts from 'shared/components/Alerts';
import { styles } from 'shared/settings/GooglePlacesAutocomplete';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';
import Utils from '../../../shared/modules/Utils';
import { getAuthState, getPatientProfileState } from '../../../shared/redux/src/StatesGetter';

const acceptedFiles: Array<string> = ['image/jpeg', 'image/png'];

const PatientAccountPersonalDataBlock = ({ profile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [profileEmail, setProfileEmail] = useState('');
  const [defaultEmail, setDefaultEmail] = useState('');
  const [profileFirstName, setProfileFirstName] = useState(profile.firstName);
  const [profileLastName, setProfileLastName] = useState(profile.lastName);
  const [profilePhoneNumber, setProfilePhoneNumber] = useState(profile.phoneNumber);
  const [profileAddress, setProfileAddress] = useState(profile.address?.addressAsString);
  const [profileRawAddress, setProfileRawAddress] = useState('');
  const [profilePicture, setProfilePicture] = useState(profile.imageUrl);
  const [pictureName, setPictureName] = useState('');
  const [pictureSize, setPictureSize] = useState(0);
  const [pictureType, setPictureType] = useState(null);
  const [pictureDataToUpload, setPictureDataToUpload] = useState(undefined);
  const [invitationCode, setInvitationCode] = useState('');
  const handleProfileEmailChange = (e) => setProfileEmail(e.target.value.trim());
  const { isProfileCompleted } = useSelector(getAuthState, shallowEqual);
  const handleProfileFirstNameChange = (e) => setProfileFirstName(e.target.value.trim());
  const handleProfileLastNameChange = (e) => setProfileLastName(e.target.value.trim());
  const phoneNumberRef = useRef(null);
  const patientProfile = useSelector(getPatientProfileState, shallowEqual);
  const { isCreatedWithSocialLogin } = patientProfile;

  const handleProfilePictureChange = (e) => {
    const picture = e.target.files[0] ?? {};

    if (!picture.name) {
      return;
    }

    setProfilePicture(URL.createObjectURL(picture));
    setPictureName(picture.name);
    setPictureSize(picture.size);
    setPictureType(picture.type === 'image/jpg' ? 'image/jpeg' : picture.type);
    setPictureDataToUpload(picture);
  };

  useEffect(() => {
    if (profileRawAddress) {
      setProfileAddress(profileRawAddress);
    }
  }, [profileRawAddress]);
  const updatePatientProfile = () => {
    if (pictureType !== null && !acceptedFiles.includes(pictureType)) {
      Alerts.errorAlert(t('settings.personalDataAcceptedFiles'));
      return;
    }

    if (
      !profileFirstName ||
      !profileLastName ||
      !profilePhoneNumber ||
      (!profileAddress && profileRawAddress === '')
    ) {
      Alerts.errorAlert(t('allFieldsMandatory'));
      return;
    }

    const pictureDataToPost = {
      originalName: pictureName,
      mimeType: pictureType,
      size: pictureSize,
    };
    const parsedPhone =
      profilePhoneNumber && Utils.withoutMetadata(parsePhoneNumber(profilePhoneNumber));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );

    const patientData = {
      email: profile.isUnconfirmedFromSocial ? profileEmail : undefined,
      firstName: profileFirstName,
      lastName: profileLastName,
      phoneNumber: getNumberAfterPossiblyEliminatingZero?.formattedNumber,
      rawAddress: profileRawAddress || undefined,
    };

    if (!isProfileCompleted) {
      _.set(patientData, 'invitationCode', invitationCode);
    }

    let payloadToUpdate;
    if (pictureType) {
      payloadToUpdate = { ...patientData, picture: pictureDataToPost };
    } else {
      payloadToUpdate = patientData;
    }

    Alerts.okCancelAlert(t('warning'), t('validations.saveProfile'), () => {
      dispatch(
        patchPatientProfileRequest({
          id: profile.id,
          toUpdate: payloadToUpdate,
          toUpload: pictureDataToUpload,
        }),
      );
    });
  };

  useEffect(() => {
    const parsedPhone =
      profile.phoneNumber && Utils.withoutMetadata(parsePhoneNumber(profile.phoneNumber));
    const getNumberAfterPossiblyEliminatingZero = Utils.getNumberAfterPossiblyEliminatingZero(
      parsedPhone?.nationalNumber,
      parsedPhone?.countryCallingCode,
    );

    setDefaultEmail(
      profile.isUnconfirmedFromSocial && profile.accountStatus === 'unconfirmed'
        ? ''
        : profile.email,
    );

    setProfileEmail(profile.email);
    setProfileFirstName(profile.firstName);
    setProfileLastName(profile.lastName);
    setProfilePhoneNumber(getNumberAfterPossiblyEliminatingZero?.formattedNumber);
    setProfileAddress(profile.address?.addressAsString);
    setProfileRawAddress('');
  }, [profile]);

  return (
    <div className="account-personal-data">
      <form>
        <div className="title-section">
          <h2>{t('settings.personalData')}</h2>
        </div>

        <div className="profile-picture">
          <div className="subtitle">{t('profilePhoto')}</div>
          <div className="change-picture">
            <Form.Group controlId="formFile" className="files-box">
              <Form.Label>{t('changeProfilePhoto')}</Form.Label>
              <Form.Control type="file" onChange={handleProfilePictureChange} />
            </Form.Group>
            <div
              className="avatar"
              style={
                profilePicture
                  ? { backgroundImage: `url(${profilePicture})` }
                  : { backgroundColor: '#E5E7E8' }
              }
            >
              {!profilePicture && (
                <div className="image-placeholder">{`${profile.firstName?.charAt(0) ?? ''}${
                  profile?.lastName?.charAt(0) ?? ''
                }`}</div>
              )}
            </div>
          </div>
          <div className="profile-picture-file-extention-tooltip">
            {t('settings.personalDataAcceptedFiles')}
          </div>
        </div>

        {!isProfileCompleted && (
          <div className="form-input form-input-invitation-code">
            <input
              autoComplete="off"
              id="invitationCode"
              name="invitationCode"
              type="invitationCode"
              value={invitationCode}
              onChange={(event) => setInvitationCode(event.target.value)}
              className="form-control"
              placeholder=" "
            />
            <label htmlFor="invitationCode">{t('invitationCode')}</label>
            <div className="profile-picture-file-extention-tooltip">{t('invitationCodeInfo')}</div>
          </div>
        )}

        <div className="personal-data">
          <div className="subtitle">{t('settings.personalData')}</div>

          <div className="form-input">
            <input
              disabled={!profile.isUnconfirmedFromSocial}
              autoComplete="off"
              id="email"
              name="email"
              type="email"
              defaultValue={defaultEmail}
              className="form-control"
              placeholder=""
              onChange={handleProfileEmailChange}
            />
            <label htmlFor="email">{t('email')}*</label>
            <div className="input-tooltip">{t('emailInfo')}</div>
          </div>

          <div className="form-input">
            <input
              autoComplete="off"
              id="firstName"
              name="firstName"
              type="text"
              className="form-control"
              defaultValue={profile.firstName}
              placeholder=" "
              onChange={handleProfileFirstNameChange}
            />
            <label htmlFor="firstName">{t('firstName')}*</label>
            <div className="input-tooltip">{t('firstNameInfo')}</div>
          </div>
          <div className="form-input">
            <input
              autoComplete="off"
              id="lastName"
              name="lastName"
              type="text"
              className="form-control"
              defaultValue={profile.lastName}
              placeholder=" "
              onChange={handleProfileLastNameChange}
            />
            <label htmlFor="lastName">{t('lastName')}*</label>
            <div className="input-tooltip">{t('lastNameInfo')}</div>
          </div>
          <div className="phone-number-with-prefix">
            <PhoneInput
              initialValueFormat="national"
              className={profilePhoneNumber && 'has-value'}
              placeholder=" "
              value={profilePhoneNumber}
              defaultCountry="RO"
              id="phoneNumber"
              name="phoneNumber"
              /* @ts-ignore */
              onChange={(value) => setProfilePhoneNumber(value)}
              ref={phoneNumberRef}
            />
            <label htmlFor="phoneNumber">{t('phoneNumber')}*</label>
          </div>
          <div className="input-tooltip">{t('phoneNumberInfo')}</div>
          <div className="address-google-places-autocomplete">
            <GooglePlacesAutocomplete
              apiKey={getEnv('GOOGLE_PLACES_API_KEY')}
              apiOptions={{ language: 'ro', region: 'ro' }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['ro'],
                },
              }}
              minLengthAutocomplete={3}
              selectProps={{
                placeholder: t('address'),
                defaultInputValue: profileAddress,
                onChange: (o) => {
                  geocodeByPlaceId(o.value.place_id)
                    .then((results) => setProfileRawAddress(JSON.stringify(results[0])))
                    .catch((error) => console.error(error));
                },
                styles,
              }}
            />
            <label htmlFor="react-select-3-input">{t('address')}</label>
            <div className="input-tooltip">{t('addressFieldMandatory')}</div>
          </div>
        </div>

        <button type="button" className="orange-button" onClick={updatePatientProfile}>
          {t('saveChanges')}
        </button>
      </form>
    </div>
  );
};

export default PatientAccountPersonalDataBlock;
